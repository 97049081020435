import React, { Component } from 'react';
import './Banner.css';
import AppText from '../AppText'

export const HomeText =
    <p className="bannerText">
        <h1 id="bannerHeader1">{AppText.Home.HomeBannerTitle}</h1>
        <br />
        <hr id="bannerHr" />{AppText.Home.HomeBannerText[0]}
        <br />{AppText.Home.HomeBannerText[1]}
</p>;

export const OfferText =
    <p className="bannerText">
        <h1 id="bannerHeader1">{AppText.Offer.OfferBannerTitle}</h1>
        <br />
        <hr id="bannerHr" />{AppText.Offer.OfferBannerText[0]}
        <br />{AppText.Offer.OfferBannerText[1]}
</p>;

export const PortfolioText =
    <p className="bannerText">
        <h1 id="bannerHeader1">{AppText.Portfolio.PortfolioBannerTitle}</h1>
        <br />
        <hr id="bannerHr" />{AppText.Portfolio.PortfolioBannerText[0]}
        <br />{AppText.Portfolio.PortfolioBannerText[1]}
</p>;

export const ContactText =
    <p className="bannerText">
        <h1 id="bannerHeader1">{AppText.Contact.ContactBannerTitle}</h1>
        <br />
        <hr id="bannerHr" />{AppText.Contact.ContactBannerText[0]}
        <br />{AppText.Contact.ContactBannerText[1]}
        <br />{AppText.Contact.ContactBannerText[2]}
    </p>;