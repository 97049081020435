import React, { Component } from 'react';
import SwiftSlider from 'react-swift-slider';
import Carousel from 'react-bootstrap/Carousel';
import { Container } from 'reactstrap';
import slider1 from '../../Content/Images/ProjectIcons/MalejLaki/MalejLakiRzut3D.jpg'
import slider2 from '../../Content/Images/ProjectIcons/Marokanska/MarokanskaRzut3d.jpg'
import slider3 from '../../Content/Images/ProjectIcons/Naleczowska/NaleczowskaRzut3d.jpg'
import slider4 from '../../Content/Images/ProjectIcons/Meander/MeanderRzut3d.jpg'
import slider5 from '../../Content/Images/ProjectIcons/Marokanska/MarokanskaRzut2d.jpg'
import slider6 from '../../Content/Images/ProjectIcons/Meander/MeanderRzut2d.jpg'
import './Home.css';

export class HomeSlider extends Component {
    static displayName = HomeSlider.name;

    render() {
        return (
            <Container className="layoutContainerFormat">
                <Carousel className = "homeCarousel">
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={slider1}
                            alt="First slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={slider2}
                            alt="Second slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={slider3}
                            alt="Third slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={slider4}
                            alt="Third slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={slider5}
                            alt="Third slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={slider6}
                            alt="Third slide"
                        />
                    </Carousel.Item>
                </Carousel>
            </Container>
        );
    }
}