import React, { Component } from 'react';
import { Form, Button } from 'react-bootstrap';
import './Footer.css'
import { FooterEventHandler } from './FooterEventHandler';

export class FooterForm extends Component {
    static displayName = FooterForm.name;
    grecaptcha;

    constructor(props) {
        super(props);
        this.state = { Email: '', Message: '',  MarketingPermission: false };
    }
    onChange = () => {
        console.log(this.state);
    }
    checkboxChange = (evt) => {
        this.setState({ MarketingPermission: evt.target.checked });
    }

    render() {
        return (
            <Form className="formFormat" onSubmit={this.onSubmit}>
                    <Form.Group controlId="secretsForm.Email">
                        <Form.Label>Adres email: </Form.Label>
                    <Form.Control
                        type="email"
                        placeholder="nazwa@domena.com"
                        name="Email"
                        value={this.state.Email}
                        onChange={e => this.setState({ Email: e.target.value })}
                    />
                    </Form.Group>
                    <Form.Group controlId="secretsForm.Message">
                        <Form.Label>Wiadomość: </Form.Label>
                    <Form.Control
                        as="textarea" rows={10}
                        placeholder="Treść wiadomości..."
                        name="Message"
                        value={this.state.Message}
                        onChange={e => this.setState({ Message: e.target.value })}
                    />
                    </Form.Group>
                <Form.Group>
                        <Form.Check
                            type="switch"
                            id="secretsForm.MarketingApproval"
                            label="Wyrażam zgodę na przetwarzanie moich danych (adres email) w celach marketingowych."
                            checked={this.state.MarketingPermission}
                            onChange={this.checkboxChange}
                        />
                    </Form.Group>
                    <Form.Group>
                    <Button type="submit" onChange={FooterEventHandler.submitForm(this)}>Wyślij</Button>
                    </Form.Group>
                </Form>
        );
    }
}
