import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { FetchData } from './components/FetchData';
import { Counter } from './components/Counter';
import './custom.css'
import { HomeSlider } from './components/AppComponents/HomeComponents/HomeSlider';
import { PortfolioAppPage } from './components/AppComponents/PortfolioAppPage';
import { Test } from './components/AppComponents/Test';
import { OfferAppPage } from './components/AppComponents/OfferAppPage';
import { HomeAppPage } from './components/AppComponents/HomeAppPage';

export default class App extends Component {
  static displayName = App.name;

  render () {
    return (
        <Layout>
        <Route exact path='/' component={HomeAppPage} />
        <Route path='/home' component={HomeAppPage} />
        <Route path='/portfolio' component={PortfolioAppPage} />
        <Route path='/offer' component={OfferAppPage} />
        
      </Layout>
    );
  }
}
