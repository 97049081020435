import React, { Component } from 'react';
import './Footer.css'
import { FooterFormMobile } from './FooterFormMobile';
import { FooterContactTableMobile } from './FooterContactTableMobile';
import { motion } from 'framer-motion'
import styled from "styled-components";
import { FooterForm } from './FooterForm';

const Container = styled(motion.div)`
  position: relative;
  max-width: 100vw;
  cursor: pointer;
  background-color: #262626;
`;
const FormContainer = styled(motion.div)`
  position: absolute;
  top: 5vh;
  left: 20.5vw;
  max-width: 30vh;
  cursor: pointer;
`;
const TableContainer = styled(motion.div)`
  position: absolute;
  top: 6vh;
  left: 60vw;
  max-width: 40vh;
  cursor: pointer;
`;

export class SecretsFooterContentMobile extends Component {
    static displayName = SecretsFooterContentMobile.name;

    render() {
        return (
            <footer className="footerLayout">
                <Container initial="rest" whileHover="hover" animate="rest" className="footerContainer">
                    <div className="footerUpBeltMobile" />
                    <FooterContactTableMobile />
                    <div className="footerDownBeltMobile" />
                    <FooterFormMobile />
                    <div className="footerDownBeltMobile" />
                </Container>
          
            </footer>
        );
    }
}
