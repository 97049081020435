export class ScreenHelper {

    static iPhone5 = 340;
    static tablet = 640;
    static smallLaptop = 768;
    static regularLaptop = 1024;
    static desktop = 1470;
    static laptop = 1200;
    
    static valueBetween(val, lim1, lim2) {
        return val < lim2 && val >= lim1;
    }
    static isSmallSmartPhone(width) {
        return width < this.iPhone5;
    }
    static isSmartPhone(width) {
        return this.valueBetween(width, this.iPhone5, this.tablet);
    }
    static isTablet(width) {
        return this.valueBetween(width, this.tablet, this.smallLaptop);
    }
    
    static isSmallLaptop(width) {
        return this.valueBetween(width, this.smallLaptop, this.laptop);
    }
    
    static isDesktop(width) {
        return width >= this.desktop;
    }
}
