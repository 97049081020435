import React, { Component } from 'react';
import './Footer.css';
import { SecretsBanner } from '../BannerComponents/SecretsBanner';
import phoneFig from '../Content/Images/Contact/9PPhoneWhite.png'
import mailFig from '../Content/Images/Contact/9PMailWhite.png'
import locFig from '../Content/Images/Contact/9PWarsawWhite.png'
import { motion } from 'framer-motion'
import styled from "styled-components";

const Container = styled(motion.div)`
  position: relative;
  max-width: 100vw;
  cursor: pointer;
  background-color: #262626;
`;
const ImageContainer = styled(motion.div)`
  position: absolute;
  top: 0vh;
  left: 5vw;
  max-width: 4vh;
  cursor: pointer;
`;
const InfoContainer = styled(motion.div)`
  position: absolute;
  top: 0.7vh;
  left: 15vw;
  width: 60vw;
  cursor: pointer;
`;

export class FooterContactTableMobile extends Component {
    static displayName = FooterContactTableMobile.name;

    render() {
        return (
            <div>
                <p className="littleIconTextMobile">W czym możemy Ci pomóc?<br />Masz jakieś pytania? <br /><b> Napisz do nas!</b></p>
                <Container className="littleIconContainer">
                    <ImageContainer>
                        <img src={phoneFig} className="littleIcon" alt="9pPhone" />
                    </ImageContainer>
                    <InfoContainer>
                        <p className="littleIconTextMobile">505-085-073</p>
                    </InfoContainer>
                </Container>
                <Container className="littleIconContainer">
                    <ImageContainer>
                        <img src={mailFig} className="littleIcon" alt="9pPhone" />
                    </ImageContainer>
                    <InfoContainer>
                        <p className="littleIconTextMobile">pracownia@9pietro.com</p>
                    </InfoContainer>
                </Container>
                <Container className="littleIconContainer">
                    <ImageContainer>
                        <img src={locFig} className="littleIcon" alt="9pPhone" />
                    </ImageContainer>
                    <InfoContainer>
                        <p className="littleIconTextMobile">Warszawa, Ursynów</p>
                    </InfoContainer>
                </Container>
            </div>
        );
    }
}
