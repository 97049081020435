import React, { Component } from 'react';
import './Offer.css'
import { motion } from 'framer-motion'
import styled from "styled-components";
import offer2d from '../../Content/Images/ProjectIcons/Naleczowska/NaleczowskaRzut2d.jpg';
import offer3d from '../../Content/Images/ProjectIcons/MalejLaki/MalejLakiRzut3D.jpg';
import offerArr from "../../Content/Images/ProjectIcons/Marokanska/MarokanskaA1.jpg";
import offerHomeSt from "../../Content/Images/MainPage/offerHomeStagingLighter.png";
import OfferText from "../OfferText";

const Container = styled(motion.div)`
  position: relative;
  max-width: 100vw;
  cursor: pointer;
`;
const ChunkContainer = styled(motion.div)`
  position: relative;
  max-width: 49vw;
  cursor: pointer;
`;
const ImgContainer = styled(motion.div)`
  position: absolute;
  top: 8vh;
  left: 0vw;
  max-width: 30vh;
  cursor: pointer;
`;
const TextContainer = styled(motion.div)`
  position: absolute;
  top: 6vh;
  left: 35vw;
  max-width: 50vh;
  cursor: pointer;
`;

export class OfferMainLeftChunk extends Component {
    static displayName = OfferMainLeftChunk.name;

    constructor(props) {
        super(props);
    }

    chooseImage() {
        if (this.props.chunkName.includes("projection2d")) {
            return offer2d;
        }
        else if (this.props.chunkName.includes("projection3d")) {
            return offer3d;
        }
        else if (this.props.chunkName.includes("arr")) {
            return offerArr;
        }
        else {
            return offerHomeSt;
        }
    }

    chooseText() {
        if (this.props.chunkName.includes("projection2d")) {
            return OfferText.projection2d.offerOffer;
        }
        else if (this.props.chunkName.includes("projection3d")) {
            return OfferText.projection3d.offerOffer;
        }
        else if (this.props.chunkName.includes("arr")) {
            return OfferText.arr.offerOffer;
        }
        else {
            return OfferText.homest.offerOffer;
        }
    }
    chooseTitle() {
        if (this.props.chunkName.includes("projection2d")) {
            return OfferText.projection2d.title;
        }
        else if (this.props.chunkName.includes("projection3d")) {
            return OfferText.projection3d.title;
        }
        else if (this.props.chunkName.includes("arr")) {
            return OfferText.arr.title;
        }
        else {
            return OfferText.homest.title;
        }
    }

    render() {
        return (
            <div className = "offerDiv">
                <Container >
                    <ImgContainer >
                        <img src={this.chooseImage()} className="offerOfferIcon" alt="2d offer 9pietro" />
                    </ImgContainer >
                    <TextContainer >
                        <h2 className="offerH2Title">{this.chooseTitle()}</h2>
                        <hr id="offerHrBlack" />
                        <p className="offerTextBlack">{this.chooseText()}</p>
                    </TextContainer >
                </Container>
            </div>
        );
    }
}
