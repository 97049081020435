import React, { Component } from 'react';
import './Test.css';
import { Container } from 'reactstrap';
import { SecretsBanner } from '../BannerComponents/SecretsBanner';
import { TestSlider } from './TestSlider';
import '../Layout.css';
import phoneFig from '../Content/Images/Contact/9PPhone.png'
import mailFig  from '../Content/Images/Contact/9PMail.png'
import locFig from '../Content/Images/Contact/9PWarsaw.png'
import { HomeSlider } from './HomeComponents/HomeSlider';


export class Test extends Component {
    static displayName = Test.name;
    constructor() {
        super();
        this.state = {
            width: window.innerWidth,
        };
    }
    componentWillMount() {
        window.addEventListener('resize', this.handleWindowSizeChange);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ width: window.innerWidth });
    };

    render() {
        const { width } = this.state;
        const isMobile = width <= 500;
        if (isMobile) {
            return (
                <div>
                    <TestSlider />
                </div>
            );
        }
        else {
            return (
                <div>
                    <SecretsBanner pageName="test" />
                    <Container className="layoutContainerFormat">
                        <TestSlider/>
                    </Container>
                </div>
            );
        }
    }
}
