import React, { Component } from 'react';
import './Home.css'
import { HomeOfferContentChunk } from './HomeOfferContentChunk';
import { HomeOfferContent } from './HomeOfferContent';

export class HomeOffer extends Component {
    static displayName = HomeOffer.name;

    render() {
        return (
            <div>
                <div className="backgroundForHomeOffer">
                    <HomeOfferContent/>
                </div>
            </div>
        );
    }
}
