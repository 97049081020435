import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
import { SecretsFooter } from '../components/FooterComponents/SecretsFooter';
import './Layout.css'
import { SecretsBanner } from './BannerComponents/SecretsBanner';

export class Layout extends Component {
  static displayName = Layout.name;

  render () {
    return (
        <div className = "appLayout">
            <NavMenu />
            {this.props.children}
            <SecretsFooter />
        </div>
    );
  }
}
