import React, { Component } from 'react';
import { SecretsBanner } from './BannerComponents/SecretsBanner';
import { Container } from 'reactstrap';

export class FetchData extends Component {
  static displayName = FetchData.name;

  constructor(props) {
    super(props);
      this.state = { forecasts: [], loading: true, width: window.innerWidth, };
  }

  componentDidMount() {
    this.populateWeatherData();
    }

     componentWillMount() {
        window.addEventListener('resize', this.handleWindowSizeChange);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ width: window.innerWidth });
    };


  static renderForecastsTable(forecasts) {
      return (
          
      <table className='table table-striped' aria-labelledby="tabelLabel">
        <thead>
          <tr>
            <th>Date</th>
            <th>Temp. (C)</th>
            <th>Temp. (F)</th>
            <th>Summary</th>
          </tr>
        </thead>
        <tbody>
          {forecasts.map(forecast =>
            <tr key={forecast.date}>
              <td>{forecast.date}</td>
              <td>{forecast.temperatureC}</td>
              <td>{forecast.temperatureF}</td>
              <td>{forecast.summary}</td>
            </tr>
          )}
        </tbody>
              </table>
    );
  }

    render() {
        const { width } = this.state;
        const isMobile = width <= 500;
        
    let contents = this.state.loading
      ? <p><em>Loading...</em></p>
      : FetchData.renderForecastsTable(this.state.forecasts);

        if (isMobile) {
            return (
                <div>

                </div>
            );
        }
        else {
            return (
                <div>
                    <SecretsBanner pageName="contact" />
                    <Container>
                        <h1 id="tabelLabel" >Weather forecast</h1>
                        <p>This component demonstrates fetching data from the server.</p>
                        {contents}
                    </Container>
                </div>
            );
        }
  }

  async populateWeatherData() {
    const response = await fetch('weatherforecast');
    const data = await response.json();
    this.setState({ forecasts: data, loading: false });
  }
}
