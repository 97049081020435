import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { HomeSlider } from './HomeComponents/HomeSlider';
import { SecretsBanner } from '../BannerComponents/SecretsBanner';
import '../Layout.css'
import { HomeOffer } from './HomeComponents/HomeOffer';
import { HomeArguments } from './HomeComponents/HomeArguments';
import { BannerMobile } from '../BannerComponents/BannerMobile';
import { HomeArgumentsMobile } from './HomeComponents/HomeArgumentsMobile';
import { HomeOfferContentMobile } from './HomeComponents/HomeOfferContentMobile';
import './HomeComponents/Home.css'
import { TestSlider } from './TestSlider';
import { ScreenHelper } from './Helpers/ScreenHelper'



export class HomeAppPage extends Component {
    static displayName = HomeAppPage.name;
    constructor() {
        super();
        this.state = {
            width: window.innerWidth,
        };
    }
    componentWillMount() {
        window.addEventListener('resize', this.handleWindowSizeChange);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ width: window.innerWidth });
    };


    render() {
        const { width } = this.state;
        if (ScreenHelper.isDesktop(width)) {
            return (
                <div>
                    <SecretsBanner pageName="home" />
                    <HomeArguments />
                    <HomeSlider />
                    <HomeOffer />
                </div>
            );
        }
        else {
            return (
                <div>
                    <BannerMobile />
                    <HomeArgumentsMobile chunkName="clock" />
                    <HomeArgumentsMobile chunkName="cash" />
                    <HomeArgumentsMobile chunkName="safety" />
                    <div>
                        <HomeSlider />
                    </div>
                    <div className="backgroundForHomeOfferMobile">
                        <HomeOfferContentMobile chunkName="projection2d" />
                        <HomeOfferContentMobile chunkName="projection3d" />
                        <HomeOfferContentMobile chunkName="arr" />
                        <HomeOfferContentMobile chunkName="homest" />
                    </div>
                </div>
            );
        }
        
    }
}
