import React, { Component } from 'react';
import './Home.css';
import { motion } from 'framer-motion'
import styled from "styled-components";
import offer2d from "./../../Content/Images/HomeImages/offer2dWhite.png";
import offer3d from "./../../Content/Images/HomeImages/offer3dWhite.png";
import offerArr from "./../../Content/Images/HomeImages/offer3dWhite.png";
import offerHomeSt from "./../../Content/Images/HomeImages/offerHomeStagingWhite.png";
import homeSafety from "./../../Content/Images/HomeImages/safety.png";
import homeCash from "./../../Content/Images/HomeImages/cash.png";
import homeClock from "./../../Content/Images/HomeImages/clock.png";
import OfferText from "../OfferText";
import { ScreenHelper } from '../Helpers/ScreenHelper';

const Container = styled(motion.div)`
  position: relative;
  max-width: 100vw;
  cursor: pointer;
`;

const ContainerSemi = styled(motion.div)`
  position: relative;
  top: 5vh;
  max-width: 60vw;
  margin:auto;
  cursor: pointer;
`;

const ImgContainer = styled(motion.div)`
  position: absolute;
  top: 5vh;
  left: 35vw;
  max-width: 30vh;
  cursor: pointer;
`;

const TextContainer = styled(motion.div)`
  position: absolute;
  top: 22vh;
  left: 10vw;
  width: 40vh;
  cursor: pointer;
`;

export class HomeOfferContentMobile extends Component {
    static displayName = HomeOfferContentMobile.name;
    constructor(props) {
        super(props);
        this.state = {
            width: window.innerWidth,
        };
    }
    chooseImage() {
        if (this.props.chunkName.includes("projection2d")) {
            return offer2d;
        }
        else if (this.props.chunkName.includes("projection3d")) {
            return offer3d;
        }
        else if (this.props.chunkName.includes("arr")) {
            return offerArr;
        }
        else if (this.props.chunkName.includes("homest")) {
            return offerHomeSt;
        }
        else if (this.props.chunkName.includes("clock")) {
            return homeClock;
        }
        else if (this.props.chunkName.includes("cash")) {
            return homeCash;
        }
        else {
            return homeSafety;
        }
    }

    chooseText() {
        if (this.props.chunkName.includes("projection2d")) {
            return OfferText.projection2d.offerHome;
        }
        else if (this.props.chunkName.includes("projection3d")) {
            return OfferText.projection3d.offerHome;
        }
        else if (this.props.chunkName.includes("arr")) {
            return OfferText.arr.offerHome;
        }
        else if (this.props.chunkName.includes("homest")) {
            return OfferText.homest.offerHome;
        }
        else if (this.props.chunkName.includes("clock")) {
            return OfferText.clock.offerHome;
        }
        else if (this.props.chunkName.includes("cash")) {
            return OfferText.cash.offerHome;
        }
        else {
            return OfferText.safety.offerHome;
        }
    }
    chooseTitle() {
        if (this.props.chunkName.includes("projection2d")) {
            return OfferText.projection2d.title;
        }
        else if (this.props.chunkName.includes("projection3d")) {
            return OfferText.projection3d.title;
        }
        else if (this.props.chunkName.includes("arr")) {
            return OfferText.arr.title;
        }
        else {
            return OfferText.homest.title;
        }
    }
    render() {
        const { width } = this.state;
        return (
            <div>
                <div className="backgroundForHomeOfferMobileElement">
                    <ContainerSemi>
                        <img src={this.chooseImage()} className="homeArgumentsIconSemi" alt="offer 9pietro" />
                        <h2 className="offerHomeH2">{this.chooseTitle()}</h2>
                        <p className="offerHomeText">{this.chooseText()}</p>
                    </ContainerSemi>
                </div>
            </div>
        );  
    }
}
