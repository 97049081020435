import React, { Component } from 'react';
import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css'

import { Gallery, Item } from 'react-photoswipe-gallery'

import MalejLaki1 from '../../Content/Images/ProjectIcons/MalejLaki/MalejLakiRzut3D.jpg'
import MalejLaki2 from '../../Content/Images/ProjectIcons/MalejLaki/MalejLakiRzut3D2.jpg'
import Marokanska from '../../Content/Images/ProjectIcons/Marokanska/MarokanskaRzut3d.jpg'
import Naleczowska from '../../Content/Images/ProjectIcons/Naleczowska/NaleczowskaRzut3d.jpg'
import Meander from '../../Content/Images/ProjectIcons/Meander/MeanderRzut3d.jpg'
import MalejLaki2Small from '../../Content/Images/ProjectIcons/Gallery/MalejLakiRzut3D2Scaled.jpg'
import MalejLaki1Small from '../../Content/Images/ProjectIcons/Gallery/MalejLakiRzut3DScaled.jpg'
import MeanderSmall from '../../Content/Images/ProjectIcons/Gallery/MeanderRzut3dScaled.jpg'
import NaleczowskaSmall from '../../Content/Images/ProjectIcons/Gallery/NaleczowskaRzut3dScaled.jpg'
import MarokanskaSmall from '../../Content/Images/ProjectIcons/Gallery/MarokanskaRzut3dScaled.jpg'
import './Portfolio.css'

const Gallery3D = () => (
    <Gallery>
        <Item
            original={MalejLaki1}
            thumbnail={MalejLaki1Small}
            width="1024"
            height="768"
        >
            {({ ref, open }) => (
                <img ref={ref} onClick={open} src={MalejLaki1Small} />
            )}
        </Item>
        <Item
            original={MalejLaki2}
            thumbnail={MalejLaki2Small}
            width="1024"
            height="768"
        >
            {({ ref, open }) => (
                <img ref={ref} onClick={open} src={MalejLaki2Small} />
            )}
        </Item>
        <Item
            original={Marokanska}
            thumbnail={MarokanskaSmall}
            width="1024"
            height="768"
        >
            {({ ref, open }) => (
                <img ref={ref} onClick={open} src={MarokanskaSmall} />
            )}
        </Item>
        <Item
            original={Naleczowska}
            thumbnail={NaleczowskaSmall}
            width="1024"
            height="768"
        >
            {({ ref, open }) => (
                <img ref={ref} onClick={open} src={NaleczowskaSmall} />
            )}
        </Item>
        <Item
            original={Meander}
            thumbnail={MeanderSmall}
            width="1024"
            height="768"
        >
            {({ ref, open }) => (
                <img ref={ref} onClick={open} src={MeanderSmall} />
            )}
        </Item>
    </Gallery>
)

export class Portfolio3DGallery extends Component {
    static displayName = Portfolio3DGallery.name;

    render() {
        return (
            <div>
                <h2 className="portfolioH2Title">Rzut 3D</h2>
                <hr id="portfolioH2TitleHr"/>
                <Gallery3D />
                <div className="galleryFormatMiddle"/>
            </div>
        );
    }
}