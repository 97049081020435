import React, { Component } from 'react';
import './Home.css'
import { motion } from 'framer-motion'
import styled from "styled-components";
import offer2d from "./../../Content/Images/HomeImages/offer2dWhite.png";
import offer3d from "./../../Content/Images/HomeImages/offer3dWhite.png";
import offerArr from "./../../Content/Images/HomeImages/offer3dWhite.png";
import offerHomeSt from "./../../Content/Images/HomeImages/offerHomeStagingWhite.png";
import homeSafety from "./../../Content/Images/HomeImages/safety.png";
import homeCash from "./../../Content/Images/HomeImages/cash.png";
import homeClock from "./../../Content/Images/HomeImages/clock.png";
import OfferText from "../OfferText";

const Container = styled(motion.div)`
  position: relative;
  max-width: 100vw;
  cursor: pointer;
`;
const ChunkContainer = styled(motion.div)`
  position: relative;
  max-width: 49vw;
  cursor: pointer;
`;
const ImgContainer = styled(motion.div)`
  position: absolute;
  top: 4vh;
  left: 0vw;
  max-width: 30vh;
  cursor: pointer;
`;
const TextContainer = styled(motion.div)`
  position: absolute;
  top: 0vh;
  left: 8vw;
  max-width: 40vh;
  cursor: pointer;
`;

export class HomeArgumentsContentChunk extends Component {
    constructor(props) {
        super(props);
    }
    static displayName = HomeArgumentsContentChunk.name;

    chooseImage() {
        if (this.props.chunkName.includes("clock")) {
            return homeClock;
        }
        else if (this.props.chunkName.includes("cash")) {
            return homeCash;
        }
        else {
            return homeSafety;
        }
    }

    chooseText() {
        if (this.props.chunkName.includes("clock")) {
            return OfferText.clock.offerHome;
        }
        else if (this.props.chunkName.includes("cash")) {
            return OfferText.cash.offerHome;
        }
        else {
            return OfferText.safety.offerHome;
        }
    }
    chooseTitle() {
        if (this.props.chunkName.includes("clock")) {
            return OfferText.clock.title;
        }
        else if (this.props.chunkName.includes("cash")) {
            return OfferText.cash.title;
        }
        else {
            return OfferText.safety.title;
        }
    }

    render() {
        return (
            <div>
                <ChunkContainer>
                    <ImgContainer>
                        <img src={this.chooseImage()} className="homeArgumentsIcon" alt="2d offer 9pietro" />
                    </ImgContainer>
                    <TextContainer>
                        <h2 className="offerHomeH2Black">{this.chooseTitle()}</h2>
                        <p className="offerHomeTextBlack">{this.chooseText()}</p>
                    </TextContainer>
                </ChunkContainer>
            </div>
        );
    }
}
