const OfferText = {
    "projection2d": {
        "title": "Rzut 2D",
        "offerOffer": "Dobry rzut to podstawa. Zawiera wszystkie potrzebne informacje: rozkład mieszkania, wielkość pomieszczeń, orientację względem stron świata, umiejscowienie szachtów instalacyjnych, wymiary, rodzaj posadzek w poszczególnych pokojach czy wyposażenie. Rzuty które znajdują się w naszej ofercie, dzięki klarownemu opracowaniu graficznemu, są czytelne dla każdego odbiorcy - w przeciwieństwie do standardowych rzutów technicznych.",
        "offerHome": "Dobry rzut to podstawa. Zawiera wszystkie potrzebne informacje: rozkład mieszkania, wielkość pomieszczeń, orientację względem stron świata, umiejscowienie szachtów instalacyjnych, wymiary, rodzaj posadzek w poszczególnych pokojach czy wyposażenie.",
    },
    "projection3d": {
        "title": "Rzut 3D",
        "offerOffer": "Podczas gdy tradycyjny plan mieszkania jest nośnikiem wszystkich technicznych informacji, rzut 3D działa na wyobraźnię potencjalnego kupca lub najemcy. Ponad 80% osób nie posiada wystarczającej wyobraźni przestrzennej by zobaczyć mieszkanie i jego potencjalne aranżacje tylko na podstawie płaskiego rzutu, bez prezentacji \"na żywo\". Dlatego też proponujemy korzystanie z rzutów 3D, które na rynku pierwotnym od dawna są przyjętym standardem. Przekazują one nie tylko informacje o układzie funkcjonalnym mieszkania, ale też oddają jego charakter, ułatwiając odbiorcy wyobrażenie sobie siebie w prezentowanch wnętrzach. Na rynku wtórnym nieruchmości jest to dotąd niespotykany rodzaj prezentacji. Dzięki niemu Twoje ogłoszenie na pewno przykuje uwagę.",
        "offerHome": "Wykonujemy rzuty 3D, które na rynku pierwotnym od dawna są przyjętym standardem. Przekazują one nie tylko informacje o układzie funkcjonalnym mieszkania, ale też oddają jego charakter, ułatwiając odbiorcy wyobrażenie sobie siebie w prezentowanch wnętrzach.",
    },
    "arr": {
        "title": "Aranżacje wnętrz",
        "offerOffer": "Prawdopodobnie poświęciłeś sporo czasu i energii urządzając swoje mieszkanie tak, aby jak najlepiej pasowało do Twojego stylu życia. Jednak każdy człowiek posiada nieco inny gust, potrzeby oraz przyzwyczajenia. Jest bardzo prawdopodobne, że osoba zainteresowana kupnem Twojego mieszkania będzie miała co do niego zupełnie inne plany niż Ty, a każde mieszkanie można dostosować do wielu scenariuszy i funkcji. Proponujemy zatem prezentację aranażacji alternatywnych - rysunki ukazujące inne warianty przestrzenne niż istniejący układ i sposób urządzenia mieszkania. Pokażemy, którą ścianę dałoby się wyburzyć lub przesunąć, aby stworzyć dodatkową sypialnię lub też otworzyć kuchnię na salon. Dzięki temu kupujący będą mogli przekonać się, że to właśnie Twojego mieszkania szukają.",
        "offerHome": "Prezentacja aranażacji alternatywnych - rysunki ukazujące inne warianty przestrzenne niż istniejący układ i sposób urządzenia mieszkania. Pokażemy, którą ścianę dałoby się wyburzyć lub przesunąć, aby stworzyć dodatkową sypialnię lub też otworzyć kuchnię na salon.",
    },
    "homest": {
        "title": "Home Staging",
        "offerOffer": "Home Staging to kolejne narzędzie, które bardzo pomaga w dobrej prezentacji nieruchomości. Stosujemy techniki dekoratorskie, które przy niewielkim nakładzie finansowym mogą szybko poprawić wrażenie, jakie oferowane przez Ciebie wnętrza wywierają na potencjalnym nabywcy. Przyjeżdżamy, robimy pomiary i zdjęcia i wprowadzamy drobne zmiany, które mogą pomoc w lepszym wyeksponowaniu charakteru i atutów Twojej nieruchomości. Dzięki temu mieszkanie może łatwiej znaleźć nabywcę.",
        "offerHome": "Stosujemy techniki dekoratorskie, które przy niewielkim nakładzie finansowym mogą szybko poprawić wrażenie, jakie oferowane przez Ciebie wnętrza wywierają na potencjalnym nabywcy. Home Staging to narzędzie, które bardzo pomaga w dobrej prezentacji nieruchomości.",
    },
    "clock": {
        "title": "8 sekund",
        "offerHome": "Ty­le ma cza­su og­ło­sze­nie, by przy­kuć u­wa­gę po­ten­cjal­ne­go kup­ca lub na­jem­cy. A­trak­cyj­ne zdję­cia i gra­fiki po­mo­gą wy­róż­nić Two­je miesz­ka­nie w mo­rzu o­fert i przy­kuć u­wa­gę od­bior­cy. Dzię­ki te­mu mo­żesz zna­leźć kup­ca lub na­jem­cę o 40 % szyb­ciej!",
    },
    "cash": {
        "title": "Lepsza cena",
        "offerHome": "Wy­eks­po­nu­je­my wszyst­kie za­le­ty Two­jej nie­ru­cho­moś­ci - za­rów­no funk­cjo­nal­ne jak i es­te­tycz­ne. O­fe­ru­je­my też pre­zen­tac­je al­ter­na­tyw­nych a­ran­żac­ji miesz­ka­nia, dzię­ki któ­rym kli­ent bę­dzie miał pew­ność, że to właś­nie two­ja nie­ru­cho­mość speł­nia je­go wy­ma­ga­nia i jest war­ta swo­jej ce­ny.",
    },
    "safety": {
        "title": "Bezpieczeństwo",
        "offerHome": "Im wię­cej in­for­mac­ji kli­ent u­zys­ka z og­ło­sze­nia in­ter­net­o­we­go, tym bar­dziej świa­do­my bę­dzie je­go wy­bór, a Ty osz­czę­dzisz czas i u­nik­niesz zbęd­ne­go ry­zy­ka. Zmniej­szy się i­lość nie­pers­pek­ty­wicz­nych kup­ców, któ­rzy dla roz­wia­nia wąt­pli­woś­ci chcie­li­by zo­ba­czyć miesz­ka­nie o­so­biś­cie.",
    }
}
export default OfferText