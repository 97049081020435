import React, { Component } from 'react';
import './Home.css'
import { HomeOfferContentChunk } from './HomeOfferContentChunk';
import { motion } from 'framer-motion'
import styled from "styled-components";

const ContainerU = styled(motion.div)`
  position: relative;
  max-width: 100vw;
  top: 0vh;
  left: 10vw;
  cursor: pointer;
  
`;
const ContainerD = styled(motion.div)`
  position: absolute;
  max-width: 100vw;
  top: 35vh;  
  left: 10vw;
  cursor: pointer;
`;
const ChunkLeftContainer = styled(motion.div)`
  position: absolute;
  top: 0vh;
  left: 4vw;
  width: 49vw;
  cursor: pointer;
`;
const ChunkRightContainer = styled(motion.div)`
  position: absolute;
  top: 0vh;
  left: 40vw;
  width: 49vw;
  cursor: pointer;
`;
const TitleContainer = styled(motion.div)`
  position: relative;
  top: 3vh;
  left: 15vw;
  width: 31vw;
  cursor: pointer;
`;

export class HomeOfferContent extends Component {
    static displayName = HomeOfferContent.name;

    render() {
        return (
            <div>
                <TitleContainer>
                <h2 className="offerHomeH2Title">Oferta</h2>
                <hr className= "offerHomeH2TitleHr"/>
                    </TitleContainer>
                    <ContainerU>
                <ChunkLeftContainer>
                        <HomeOfferContentChunk chunkName="projection2d" />
                </ChunkLeftContainer>
                <ChunkRightContainer>
                    <HomeOfferContentChunk chunkName="projection3d" />
                    </ChunkRightContainer>
                </ContainerU>
                <ContainerD>
                    <ChunkLeftContainer>
                        <HomeOfferContentChunk chunkName="arr" />
                    </ChunkLeftContainer>
                    <ChunkRightContainer>
                        <HomeOfferContentChunk chunkName="homest" />
                    </ChunkRightContainer>
                </ContainerD>
            </div>
        );
    }
}
