import React, { Component } from 'react';
import './Home.css'
import { HomeArgumentsContentChunk } from './HomeArgumentsContentChunk';
import { motion } from 'framer-motion'
import styled from "styled-components";

const Container = styled(motion.div)`
  position: relative;
  max-width: 100vw;
  top: 5vh;
  left: 10vw;
  cursor: pointer;
  
`;
const ChunkLeftContainer = styled(motion.div)`
  position: absolute;
  top: 0vh;
  left: 0vw;
  width: 25vw;
  cursor: pointer;
`;
const ChunkMidContainer = styled(motion.div)`
  position: absolute;
  top: 0vh;
  left: 27vw;
  width: 25vw;
  cursor: pointer;
`;
const ChunkRightContainer = styled(motion.div)`
  position: absolute;
  top: 0vh;
  left: 53vw;
  width: 25vw;
  cursor: pointer;
`;
const ChunkPipelineContainer1 = styled(motion.div)`
  position: absolute;
  top: 2vh;
  left: 26vw;
  width: 4vw;
  cursor: pointer;
`;
const ChunkPipelineContainer2 = styled(motion.div)`
  position: absolute;
  top: 2vh;
  left: 53vw;
  width: 4vw;
  cursor: pointer;
`;

export class HomeArgumentsContent extends Component {
    static displayName = HomeArgumentsContent.name;

    render() {
        return (
            <div>
                <Container>
                    <ChunkLeftContainer>
                        <HomeArgumentsContentChunk chunkName="clock" />
                    </ChunkLeftContainer>
                    <ChunkPipelineContainer1>
                    <div className = "pipeline"></div>
                        </ChunkPipelineContainer1>
                        <ChunkMidContainer>
                        <HomeArgumentsContentChunk chunkName="cash" />
                    </ChunkMidContainer>
                    <ChunkPipelineContainer2>
                        <div className="pipeline"></div>
                    </ChunkPipelineContainer2>
                    <ChunkRightContainer>
                        <HomeArgumentsContentChunk chunkName="safety" />
                    </ChunkRightContainer>
                </Container>
            </div>
        );
    }
}
