export class FooterEventHandler {

    static submitForm = (object) => {

        const data = new URLSearchParams();
        data.append('Email', object.state.Email);
        data.append('Message', object.state.Message);
        data.append('MarketingPermission', object.state.MarketingPermission);
        console.log(object.state);

        window.grecaptcha.ready(function () {
            window.grecaptcha.execute('6Lf2FHEbAAAAAA5Pyp7Jc01_97JVVF52ppPauVMx', { action: 'submit' }).then(function (token) {
                data.append('g-recaptcha-response', token);

                fetch('secrets-api/form/send-and-save', {
                    method: 'POST',
                    body: data
                }).then(function (response) {
                    console.log(response)
                    return response.json();
                });
            });
        });
    }
}
