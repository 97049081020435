import React, { Component } from 'react';
import './Home.css'
import { HomeOfferContentChunk } from './HomeOfferContentChunk';
import { HomeOfferContent } from './HomeOfferContent';
import { HomeArgumentsContent } from './HomeArgumentsContent';
import OfferText from "../OfferText";

export class HomeArguments extends Component {
    static displayName = HomeArguments.name;

    render() {
        return (
            <div>
                <div className="backgroundForHomeArguments">
                   <HomeArgumentsContent/>
                </div>
            </div>
        );
    }
}
