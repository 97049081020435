import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { Portfolio3DGallery } from './PortfolioComponents/Portfolio3DGallery';
import { Portfolio2DGallery } from './PortfolioComponents/Portfolio2DGallery';
import { SecretsBanner } from '../BannerComponents/SecretsBanner';
import '../Layout.css';
import { BannerMobile } from '../BannerComponents/BannerMobile';
import './PortfolioComponents/Portfolio.css';
import { ScreenHelper } from './Helpers/ScreenHelper';

export class PortfolioAppPage extends Component {
    static displayName = PortfolioAppPage.name;
    constructor() {
        super();
        this.state = {
            width: window.innerWidth,
        };
    }
    componentWillMount() {
        window.addEventListener('resize', this.handleWindowSizeChange);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ width: window.innerWidth });
    };

    render() {
        const { width } = this.state;
        if (ScreenHelper.isDesktop(width)) {
            return (
                <div>
                    <SecretsBanner pageName="portfolio" />
                    <Container className="layoutContainerFormat">
                        <Portfolio3DGallery />
                        <Portfolio2DGallery />
                    </Container>
                </div>
            );
        }
        else {
            return (
                <div>
                    <BannerMobile />
                    <div className="mobileGallerySemi2">
                        <Container>
                            <Portfolio3DGallery />
                            <Portfolio2DGallery />
                        </Container>
                    </div>
                </div>
            );
        }
    }
}
