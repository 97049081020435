import React, { Component } from 'react';
import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css'

import { Gallery, Item } from 'react-photoswipe-gallery'

import MalejLaki from '../../Content/Images/ProjectIcons/MalejLaki/MalejLakiRzut2D.jpg'
import Marokanska from '../../Content/Images/ProjectIcons/Marokanska/MarokanskaRzut2d.jpg'
import Naleczowska from '../../Content/Images/ProjectIcons/Naleczowska/NaleczowskaRzut2d.jpg'
import Meander from '../../Content/Images/ProjectIcons/Meander/MeanderRzut2d.jpg'

import MalejLaki1Small from '../../Content/Images/ProjectIcons/Gallery/MalejLakiRzut2DScaled.jpg'
import MeanderSmall from '../../Content/Images/ProjectIcons/Gallery/MeanderRzut2dScaled.jpg'
import NaleczowskaSmall from '../../Content/Images/ProjectIcons/Gallery/NaleczowskaRzut2dScaled.jpg'
import MarokanskaSmall from '../../Content/Images/ProjectIcons/Gallery/MarokanskaRzut2dScaled.jpg'
import './Portfolio.css'

const Gallery2D = () => (
    <Gallery>
        
        <Item
            original={MalejLaki}
            thumbnail={MalejLaki1Small}
            width="1024"
            height="768"
        >
            {({ ref, open }) => (
                <img ref={ref} onClick={open} src={MalejLaki1Small} />
            )}
        </Item>
        <Item
            original={Marokanska}
            thumbnail={MarokanskaSmall}
            width="1024"
            height="768"
        >
            {({ ref, open }) => (
                <img ref={ref} onClick={open} src={MarokanskaSmall} />
            )}
        </Item>
        <Item
            original={Naleczowska}
            thumbnail={NaleczowskaSmall}
            width="1024"
            height="768"
        >
            {({ ref, open }) => (
                <img ref={ref} onClick={open} src={NaleczowskaSmall} />
            )}
        </Item>
        <Item
            original={Meander}
            thumbnail={MeanderSmall}
            width="1024"
            height="768"
        >
            {({ ref, open }) => (
                <img ref={ref} onClick={open} src={MeanderSmall} />
            )}
        </Item>
    </Gallery>
)

export class Portfolio2DGallery extends Component {
    static displayName = Portfolio2DGallery.name;

    render() {
        return (
            <div>
                <h2 className="portfolioH2Title">Rzut 2D</h2>
                <hr id="portfolioH2TitleHr" />
                <Gallery2D/>
                <div className="galleryFormatBottom" />
            </div>
        );
    }
}