import React from 'react';
import 'rc-banner-anim/assets/index.css';
import AppText from '../AppText'
import './Banner.css';
import logo from '../Content/Images/Headers/WhiteNewLogo.png';

import { HomeText, ContactText, PortfolioText, OfferText } from './BannerTexts';
import { slideLeft } from './BannerConsts';
import { showUp } from './BannerConsts';
import { Container } from './BannerConsts';
import { ContainerSemi } from './BannerConsts';
import { TextContainer } from './BannerConsts';
import { LogoContainer } from './BannerConsts';
import { LogoContainerMobile } from './BannerConsts';
import { ScreenHelper } from '../AppComponents/Helpers/ScreenHelper';

export class BannerMobile extends React.Component {
    static displayName = BannerMobile.name;
    constructor() {
        super();
    }
    render() {
        return (
            <div className="logoBackgroundMobile">
                <ContainerSemi initial="rest" whileHover="rest" animate="rest">
                    <img src={logo} className="logoSemiMobile" alt="Logo" />
                </ContainerSemi>
            </div>
        );
    }
}