import React, { Component } from 'react';
import { BannerLogo } from './BannerLogo';
import './Banner.css';

export class SecretsBanner extends React.Component {
    constructor(props) {
        super(props);
    }
    static displayName = SecretsBanner.name; 
    render() {
        return (
            <div className="logoBackground">
                <BannerLogo pageName={this.props.pageName} />
            </div>
        );
    }
}