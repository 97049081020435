import React from 'react';
import 'rc-banner-anim/assets/index.css';
import AppText from '../AppText'
import './Banner.css';
import logo from '../Content/Images/Headers/WhiteNewLogo.png';

import { HomeText, ContactText, PortfolioText, OfferText } from './BannerTexts';
import { slideLeft } from './BannerConsts';
import { showUp } from './BannerConsts';
import { Container } from './BannerConsts';
import { TextContainer } from './BannerConsts';
import { LogoContainer } from './BannerConsts';

export class BannerLogo extends React.Component {
    constructor(props) {
        super(props);
    }
    static displayName = BannerLogo.name;

    getBannerText() {
        if (this.props.pageName.includes("home")) {
            console.log(document.location);
            return HomeText;
        }
        else if (this.props.pageName.includes("offer")) {
            console.log(document.location);
            return OfferText;
        }
        else if (this.props.pageName.includes("portfolio")) {
            console.log(document.location);
            return PortfolioText;
        }
        else {
            console.log(document.location);
            return ContactText;
        }
    }

    render() {
        return (
            <Container initial="rest" animate="hover">
                <LogoContainer variants={slideLeft}>
                    <img src={logo} className="logo" alt="Logo" />
                </LogoContainer>
                <TextContainer variants={showUp}>
                    {this.getBannerText()}
                </TextContainer>
            </Container>
        );
    }
}