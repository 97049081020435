import React, { Component } from 'react';
import { Form, Button } from 'react-bootstrap';
import './Footer.css'
import { FooterEventHandler } from './FooterEventHandler';

export class FooterFormMobile extends Component {
    static displayName = FooterFormMobile.name;
    grecaptcha;
    constructor(props) {
        super(props);
        this.state = { Email: '', Message: '', MarketingPermission: "false" };
    }
    render() {
        return (
            <Form className="formFormatMobile">
                <Form.Group controlId="secretsForm.Email">
                    <Form.Label>Adres email: </Form.Label>
                    <Form.Control type="email" placeholder="nazwa@domena.com" />
                </Form.Group>
                <Form.Group controlId="secretsForm.Message">
                    <Form.Label>Wiadomość: </Form.Label>
                    <Form.Control as="textarea" rows={10} placeholder="Treść wiadomości..." />
                </Form.Group>
                <Form.Group>
                    <Form.Check
                        type="switch"
                        id="secretsForm.MarketingApproval"
                        label="Wyrażam zgodę na przetwarzanie moich danych (adres email) w celach marketingowych."
                        checked={this.state.MarketingPermission}
                        onChange={this.checkboxChange}
                    />
                </Form.Group>
                <Form.Group>
                    <Button type="submit" onChange={FooterEventHandler.submitForm(this)}>Wyślij</Button>
                </Form.Group>
            </Form>
        );
    }
}
