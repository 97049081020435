const AppText = {
    "Home": {
        "HomeBannerTitle": "Graficzne wsparcie sprzedaży nieruchomości",
        "HomeBannerText": ["Sprzedajesz lub wynajmujesz mieszkanie? Zależy Ci, żeby wszystko poszło szybko i sprawnie?", "Dobra prezentacja to podstawa. Nasz zespół architektów specjalizuje się w graficznej prezentacji nieruchomości."],
    },
    "Offer": {
        "OfferBannerTitle": "Oferta",
        "OfferBannerText": ["Tworzymy wizualizacje nieruchomości w formie rzutów 3D, które pozwalają zainteresowanym objąć wzrokiem całe mieszkanie.", "W ofercie posiadamy również graficzne rzuty 2D, łatwiejsze do odczytania przez potencjalnego klienta, aranżacje wnętrz oraz home staging."],
    },
    "Portfolio": {
        "PortfolioBannerTitle": "Portfolio",
        "PortfolioBannerText": ["Zestaw przykładowych prac wykonanych", "przez nasz zespół."],
    },
    "Contact": {
        "ContactBannerTitle": "Kontakt",
        "ContactBannerText": ["505-085-073", "pracownia@9pietro.com", "Warszawa, Ursynów"],
    }
}
export default AppText