import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { SecretsBanner } from '../BannerComponents/SecretsBanner';
import '../Layout.css'
import { OfferMainLeftChunk } from './OfferComponents/OfferMainLeftChunk';
import { OfferMainRightChunk } from './OfferComponents/OfferMainRightChunk';
import { OfferContentMobile } from './OfferComponents/OfferContentMobile';
import './OfferComponents/Offer.css'
import { BannerMobile } from '../BannerComponents/BannerMobile';
import { ScreenHelper } from './Helpers/ScreenHelper';

export class OfferAppPage extends Component {
    static displayName = OfferAppPage.name;
    constructor() {
        super();
        this.state = {
            width: window.innerWidth,
        };
    }
    componentWillMount() {
        window.addEventListener('resize', this.handleWindowSizeChange);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ width: window.innerWidth });
    };

    render() {
        const { width } = this.state;
        if (ScreenHelper.isDesktop(width)) {
            return (
                <div>
                    <SecretsBanner pageName="offer" />
                    <Container className="layoutContainerFormat">
                        <OfferMainLeftChunk chunkName="projection2d" />
                        <OfferMainRightChunk chunkName="projection3d" />
                        <OfferMainLeftChunk chunkName="arr" />
                        <OfferMainRightChunk chunkName="homest" />
                    </Container>
                </div>
            );
        }
        else  {
            return (
                <div>
                    <BannerMobile />
                    <div className="offerPresentationSpace">
                        <OfferContentMobile chunkName="projection2d" />
                        <OfferContentMobile chunkName="projection3d" />
                        <OfferContentMobile chunkName="arr" />
                        <OfferContentMobile chunkName="homest" />
                    </div>
                </div>
            );
        }
        
    }
}
