import React, { Component } from 'react';
import './Home.css'
import homeSafety from "./../../Content/Images/HomeImages/safety.png";
import homeCash from "./../../Content/Images/HomeImages/cash.png";
import homeClock from "./../../Content/Images/HomeImages/clock.png";
import OfferText from "../OfferText";
import { motion } from 'framer-motion'
import styled from "styled-components";
import { ScreenHelper } from '../Helpers/ScreenHelper';

const Container = styled(motion.div)`
  position: relative;
  max-width: 100vw;
  cursor: pointer;
`;
const ContainerSemi = styled(motion.div)`
  position: relative;
  top: 5vh;
  max-width: 60vw;
  margin:auto;
  cursor: pointer;
`;

const ImgContainer = styled(motion.div)`
  position: absolute;
  top: 5vh;
  left: 35vw;
  max-width: 30vh;
  cursor: pointer;
`;
const TextContainer = styled(motion.div)`
  position: absolute;
  top: 22vh;
  left: 10vw;
  width: 40vh;
  cursor: pointer;
`;


export class HomeArgumentsMobile extends Component {
    static displayName = HomeArgumentsMobile.name;
    constructor() {
        super();
    }
    chooseImage() {
        if (this.props.chunkName.includes("clock")) {
            return homeClock;
        }
        else if (this.props.chunkName.includes("cash")) {
            return homeCash;
        }
        else {
            return homeSafety;
        }
    }

    chooseText() {
        if (this.props.chunkName.includes("clock")) {
            return OfferText.clock.offerHome;
        }
        else if (this.props.chunkName.includes("cash")) {
            return OfferText.cash.offerHome;
        }
        else {
            return OfferText.safety.offerHome;
        }
    }
    chooseTitle() {
        if (this.props.chunkName.includes("clock")) {
            return OfferText.clock.title;
        }
        else if (this.props.chunkName.includes("cash")) {
            return OfferText.cash.title;
        }
        else {
            return OfferText.safety.title;
        }
    }
    render() {
        return (
            <div>
                <div className="backgroundForHomeArgumentsMobile">
                    <ContainerSemi>
                        <img src={this.chooseImage()} className="homeArgumentsIconSemi" alt="offer 9pietro" />
                        <h2 className="offerHomeH2Black">{this.chooseTitle()}</h2>
                        <p className="offerHomeTextBlack">{this.chooseText()}</p>
                    </ContainerSemi>
                </div>
            </div>
        );

    }
}
