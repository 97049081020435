import React, { Component } from 'react';
import { ScreenHelper } from '../AppComponents/Helpers/ScreenHelper';
import './Footer.css'
import { SecretsFooterContent } from './SecretsFooterContent';
import { SecretsFooterContentMobile } from './SecretsFooterContentMobile';

export class SecretsFooter extends Component {
    static displayName = SecretsFooter.name;
    constructor() {
        super();
        this.state = {
            width: window.innerWidth,
        };
    }
    componentWillMount() {
        window.addEventListener('resize', this.handleWindowSizeChange);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ width: window.innerWidth });
    };


    render() {
        const { width } = this.state;
        
        if (ScreenHelper.isDesktop(width)) {
            return (
                <div>
                    <div className="footerUpBelt" />
                    <div className="SecretsFooterMain">
                        <SecretsFooterContent />
                    </div>
                    <div className="footerDownBelt" />
                </div>
            );
        }
        else {
            return (
                <div>
                    <SecretsFooterContentMobile />
                </div>
            );
        }
    }
}
