import React, { Component } from 'react';
import './Banner.css';
import { motion } from 'framer-motion'
import styled from "styled-components";

export const slideLeft = {
    rest: {
        x: 0
    },
    hover: {
        x: -320,
        transition: { duration: 2 }
    }
};

export const showUp = {
    rest: {
        opacity: 0
    },
    hover: {
        opacity: 1,
        transition: { duration: 2 }
    }
};
export const Container = styled(motion.div)`
  position: relative;
  max-width: 100vw;
  cursor: pointer;
`;
export const ContainerSemi = styled(motion.div)`
  position: relative;
  max-width: 100vw;
  top: 5vw;
  cursor: pointer;
`;
export const TextContainer = styled(motion.div)`
  position: absolute;
  top: 10vh;
  left: 56vw;
  max-width: 40vh;
  cursor: pointer;
`;
export const LogoContainer = styled(motion.div)`
  position: absolute;
  top: 5vh;
  left: 33vw;
  max-width: 30vh;
  cursor: pointer;
`;
export const LogoContainerMobile = styled(motion.div)`
  position: absolute;
  top: 5vh;
  left: 12vw;
  max-width: 30vh;
  cursor: pointer;
`;