import React, { Component } from 'react';
import './Offer.css';
import { motion } from 'framer-motion'
import styled from "styled-components";
import offer2d from '../../Content/Images/ProjectIcons/Naleczowska/NaleczowskaRzut2d.jpg';
import offer3d from '../../Content/Images/ProjectIcons/MalejLaki/MalejLakiRzut3D.jpg';
import offerArr from "../../Content/Images/ProjectIcons/Marokanska/MarokanskaA1.jpg";
import offerHomeSt from "../../Content/Images/MainPage/offerHomeStagingLighter.png";
import homeSafety from "./../../Content/Images/HomeImages/safety.png";
import homeCash from "./../../Content/Images/HomeImages/cash.png";
import homeClock from "./../../Content/Images/HomeImages/clock.png";
import OfferText from "../OfferText";
import { ScreenHelper } from '../Helpers/ScreenHelper';

const Container = styled(motion.div)`
  position: relative;
  max-width: 100vw;
  cursor: pointer;
`;
const ContainerSemi = styled(motion.div)`
  position: relative;
  top: 5vh;
  max-width: 60vw;
  margin:auto;
  cursor: pointer;
`;


const ImgContainer = styled(motion.div)`
  position: absolute;
  top: 5vh;
  left: 10vw;
  max-width: 30vh;
  cursor: pointer;
`;
const TextContainer = styled(motion.div)`
  position: absolute;
  top: 40vh;
  left: 10vw;
  width: 40vh;
  cursor: pointer;
`;

export class OfferContentMobile extends Component {
    static displayName = OfferContentMobile.name;
    constructor(props) {
        super(props);
    }
    chooseImage() {
        if (this.props.chunkName.includes("projection2d")) {
            return offer2d;
        }
        else if (this.props.chunkName.includes("projection3d")) {
            return offer3d;
        }
        else if (this.props.chunkName.includes("arr")) {
            return offerArr;
        }
        else if (this.props.chunkName.includes("homest")) {
            return offerHomeSt;
        }
        else if (this.props.chunkName.includes("clock")) {
            return homeClock;
        }
        else if (this.props.chunkName.includes("cash")) {
            return homeCash;
        }
        else {
            return homeSafety;
        }
    }

    chooseText() {
        if (this.props.chunkName.includes("projection2d")) {
            return OfferText.projection2d.offerOffer;
        }
        else if (this.props.chunkName.includes("projection3d")) {
            return OfferText.projection3d.offerOffer;
        }
        else if (this.props.chunkName.includes("arr")) {
            return OfferText.arr.offerOffer;
        }
        else {
            return OfferText.homest.offerOffer;
        }
    }
    chooseTitle() {
        if (this.props.chunkName.includes("projection2d")) {
            return OfferText.projection2d.title;
        }
        else if (this.props.chunkName.includes("projection3d")) {
            return OfferText.projection3d.title;
        }
        else if (this.props.chunkName.includes("arr")) {
            return OfferText.arr.title;
        }
        else {
            return OfferText.homest.title;
        }
    }
    render() {
        if (this.props.chunkName.includes("projection2d")) {
            return (
                <div>
                    <div className="backgroundForOfferMobileElementSmall">
                        <ContainerSemi>
                            <img src={this.chooseImage()} className="offerOfferIconSemi" alt="offer 9pietro" />                          
                                <h2 className="offerH2Title">{this.chooseTitle()}</h2>
                                <p className="offerTextBlack">{this.chooseText()}</p>
                        </ContainerSemi>
                    </div>
                </div>
            );
        }
        else {
            return (
                <div>
                    <div className="backgroundForOfferMobileElement">
                        <ContainerSemi>
                            <img src={this.chooseImage()} className="offerOfferIconSemi" alt="offer 9pietro" />
                            <h2 className="offerH2Title">{this.chooseTitle()}</h2>
                            <p className="offerTextBlack">{this.chooseText()}</p>
                        </ContainerSemi>
                    </div>
                </div>
            );
        }        
    }
}
