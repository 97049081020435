import React, { Component } from 'react';
import './Footer.css'
import { FooterForm } from './FooterForm';
import { FooterContactTable } from './FooterContactTable';
import { motion } from 'framer-motion'
import styled from "styled-components";

const Container = styled(motion.div)`
  position: relative;
  max-width: 100vw;
  cursor: pointer;
  background-color: #262626;
`;
const FormContainer = styled(motion.div)`
  position: absolute;
  top: 5vh;
  left: 15vw;
  max-width: 30vh;
  cursor: pointer;
`;
const TableContainer = styled(motion.div)`
  position: absolute;
  top: 6vh;
  left: 62vw;
  max-width: 40vh;
  cursor: pointer;
`;

export class SecretsFooterContent extends Component {
    static displayName = SecretsFooterContent.name;

    render() {
        return (
            <footer className="footerLayout">
                <Container initial="rest" whileHover="hover" animate="rest" className = "footerContainer">
                    <FormContainer>
                        <FooterForm/>
                    </FormContainer>
                    <TableContainer>
                        <FooterContactTable/>
                    </TableContainer>
                </Container>
          
            </footer>
        );
    }
}
