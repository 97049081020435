import  React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { Container } from 'reactstrap';
import './Test.css';

import slider1 from './../Content/Images/ProjectIcons/MalejLaki/MalejLakiRzut3D.jpg'
import slider2 from './../Content/Images/ProjectIcons/Marokanska/MarokanskaRzut3d.jpg'
import slider3 from './../Content/Images/ProjectIcons/Naleczowska/NaleczowskaRzut3d.jpg'
import slider4 from './../Content/Images/ProjectIcons/Meander/MeanderRzut3d.jpg'
import slider5 from './../Content/Images/ProjectIcons/Marokanska/MarokanskaRzut2d.jpg'
import slider6 from './../Content/Images/ProjectIcons/Meander/MeanderRzut2d.jpg'
import { Component } from 'react';

export class TestSlider extends Component {
    static displayName = TestSlider.name;

    render() {
        return (
            <Container className="layoutContainerFormat">
            <Carousel>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={slider1}
                        alt="First slide"
                    />
                    <Carousel.Caption>
                        <h3>First slide label</h3>
                        <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={slider2}
                        alt="Second slide"
                    />

                    <Carousel.Caption>
                        <h3>Second slide label</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={slider3}
                        alt="Third slide"
                    />

                    <Carousel.Caption>
                        <h3>Third slide label</h3>
                        <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
                    </Carousel.Caption>
                </Carousel.Item>
                </Carousel>
            </Container>
        );
    }
}